import React, { useEffect, useState } from 'react';
import { FileUpload } from '../DataMaintenance/FileUpload';
import { useTranslation } from 'react-i18next';
import { Autocomplete, Box, Chip, FormControl, Grid, InputAdornment, MenuItem, Pagination, Select, Stack, TextField, Button, Tooltip, IconButton } from '@mui/material';
import { IHandlePageChangeProps, IMaintenanceTableProps, IPropertyDefault, ITableHeaders } from '../../../types';
import { ECellType, ECommonDialogType, TableHeaders, foreignKeysForTable, initialSortField, EDataMaintenanceType, EDataTableType, EModuleType, EActionType, RecordLength, deleteFields, searchFields, FillFieldsWithValueAsNo, ECompareStatusCellValues, EApplicability } from '../../data/Constants';
import { DataGrid, gridStringOrNumberComparator, GridColDef, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridCellParams, GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid';
import { countryCodes } from '../../localization';
import { ExportDetails } from '../ExportDetails';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
import InfoIcon from '@mui/icons-material/Info';
import CompareIcon from '@mui/icons-material/Compare';
import { formatDateString, getMaxDate } from '../../services/DataHelperFunctions';
import { AppDispatch, AppState } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { setDialogMessage } from '../../store/states/DialogSlice';

const displayImport = {
  [EDataMaintenanceType.SalesText]: true,
  [EDataMaintenanceType.Images]: false,
  [EDataMaintenanceType.ProductHierarchy]: true,
  [EDataMaintenanceType.Prices]: true,
  [EDataMaintenanceType.LanguageAssociation]: false,
  [EDataMaintenanceType.MarketAssociation]: false
}

//This method return the property default value
function getDefaultValue(value: IPropertyDefault) {
  return value ? value.name : ''
}

//This method renders the value to the  data table cell based on ECellType
const valueGetterData = (header: ITableHeaders, params: { [x: string]: any; }, t: (arg0: string) => any) => {
  let val = '';
  if (header.type === ECellType.ObjectChips || header.type === ECellType.ArrayChips) {
    val = params[header.field] ? params[header.field].map(role => role.code || role.name || role).join(', ') : ''
    return val;
  } else if (header.type === ECellType.Object) {
    val = params[header.field];
    return getDefaultValue(val);
  } else if (header.type === ECellType.Images) {
    val = params[header.field].map(role => role.type).join(', ');
    return val;
  } else if (header.type === ECellType.Date) {
    val = formatDateString(params[header.field]);
    return val;
  } else if (header.type === ECellType.MaxDate) {
    const datesArr = params[header.key].map(data => data.modifiedOn)
    return getMaxDate(datesArr);
  } else if (typeof params[header.field] === 'boolean') {
    return params[header.field] ? t('labels.yes') : t('labels.no');
  } else {
    return params[header.field];
  }
}

//This method return the class name for the dataTable columns
const getHeaderClass = (type: string, header) => {
  if ((type === EDataTableType.PropertyAssociation || type === EDataTableType.FeaturePropertyAssociation) && (header?.applicability && header?.applicability.includes(EApplicability.Master))) {
    return 'property-header';
  }
  return '';
}

const createGridColumns = (tableHeaders: any[], t, openDialog, uniqueKey, type) => {
  return tableHeaders.map(header => {

    return {
      headerClassName: getHeaderClass(type, header),
      field: header.field,
      headerName: !header.hasNoTranslation ? t('labels.' + header.field) : header.field, //To assign our own custom value in dropdown of filter button in datagrid
      sortable: header.sort,
      width: header.width,
      type: header.type,
      filterable: header.filter !== false,
      cellClassName: (params: GridCellParams) => { //To style the cells of individual column
        if (FillFieldsWithValueAsNo.includes(header.field) && !params.row[header.field]) {
          return 'cellWithValueAsNo'
        } else if (params.row[ECompareStatusCellValues.Status] == ECompareStatusCellValues.Added) {
          return 'cellStylesPH' + ECompareStatusCellValues.Added
        } else if (params.row[ECompareStatusCellValues.Status] == ECompareStatusCellValues.Deleted) {
          return 'cellStylesPH' + ECompareStatusCellValues.Deleted
        } else if (params.row[ECompareStatusCellValues.Status] == ECompareStatusCellValues.Updated) {
          return params.row[ECompareStatusCellValues.ModifiedCells]?.map((modValue: string) => modValue.toLowerCase()).includes(header.field.toLowerCase()) ?
            'cellStylesPH' + ECompareStatusCellValues.Updated : ''
        } else {
          return '';
        }
      },
      renderHeader: () => { //renderHeader is used to render custom component or styling
        return <span className="searchIconForColumn">{header.searchable ? <SearchIcon color="primary" /> : null}{!header.hasNoTranslation ? t('labels.' + header.field) : header.field}</span>
      },
      renderCell: (params: GridRenderCellParams) => { //renderCell is used to render custom component or styling
        return createCell(params, header, t, openDialog, uniqueKey, type)
      },
      valueGetter: (_, params: GridValidRowModel) => { //valueGetter is used to return values to filter function in datagrid 
        return valueGetterData(header, params, t);
      },
      sortComparator: gridStringOrNumberComparator, // To sort the table as we are using renderCell
    }
  })
}

const handleSearch = (props) => {

  const { newVal, recordsPerPage, token, getDataList, setPage, productId, dispatch } = props;
  dispatch(getDataList({ token, page: 1, limit: recordsPerPage, searchCode: newVal, productId: productId }));
  setPage(1);
}

const handlePageChange = (handlePageChangeProps: IHandlePageChangeProps) => {
  const { value, recordsPerPage, searchValue, stateData, setFilteredData, productId, props, dispatch } = handlePageChangeProps;
  if (!searchValue && stateData.data[value] || searchValue && stateData.searchData[value]) {
    if (searchValue) {
      setFilteredData(stateData.searchData[value])
    } else {
      setFilteredData(stateData.data[value])
    }
  } else {
    dispatch(props.getDataList({ token: props.token, page: value, limit: recordsPerPage, searchCode: searchValue, productId: productId }));
  }
}

const handleRecordsChange = (handleRecordsChangeProps) => {
  const { records, searchValue, setPage, productId, props, dispatch } = handleRecordsChangeProps;
  dispatch(props.getDataList({ token: props.token, page: 1, limit: records, searchCode: searchValue, productId: productId }));
  setPage(1);
}

const handleRefresh = (handleRefreshProps) => {
  const { props, page, recordsPerPage, productId, dispatch } = handleRefreshProps;
  dispatch(props.getDataList({ token: props.token, page: page, limit: recordsPerPage, searchCode: null, productId: productId }));
}

function handleModuleTypeChange(handleModuleTypeChangeProps) {
  const { tableData, page, setStateData, setFilteredData, setSearchValue, setRecordsPerPage, setPageCount } = handleModuleTypeChangeProps;
  setStateData(tableData);
  if (tableData.searchKey) {
    setFilteredData(tableData.searchData[page]);
    setSearchValue(tableData.searchKey);
  } else if (tableData.data[page]) {
    setFilteredData(tableData.data[page]);
    setSearchValue('');
  }
  setRecordsPerPage(tableData.recordsPerPage || RecordLength[0])
  setPageCount(Math.ceil(tableData.totalCount / tableData.recordsPerPage) || 1);
}

function loadFormData(props, type, tableData, page, recordsPerPage, searchValue, dispatch: AppDispatch) {
  //To make an API call to store data in global state if not available or  Make an API call when it's Compare Product Hierarchy
  if (type === EDataMaintenanceType.CompareProductHierarchy || type === EDataMaintenanceType.FeaturePropertyAssociation || !tableData.data[1]) {
    dispatch(props.getDataList({ token: props.token, page: page, limit: recordsPerPage, searchValue: searchValue, productId: props.productId }))
  }
}

function inverseCheckForArray(deleteField, fdata, inverseCheckForDelete) {
  let inverseCheck = false;
  for (let i = 0; i < deleteField.length; i++) {
    inverseCheck = inverseCheck === (inverseCheckForDelete[i] ? !fdata[deleteField[i]] : fdata[deleteField[i]]);
  }
  return inverseCheck;
}

function disableDeleteBtn(disableDeleteProps: any) {
  const { filteredData, selectionModel, deleteField, inverseCheckForDelete, setDisableDelete, stateData, type } = disableDeleteProps;
  const nonDeleteEntries = filteredData.filter(fdata => { //Enable delete button based on isDefault and isActive fields
    const innerEntries = selectionModel.filter(entry => {
      let inverseCheck = false;
      if (Array.isArray(deleteField)) {
        inverseCheck = inverseCheckForArray(deleteField, fdata, inverseCheckForDelete);
      } else {
        inverseCheck = inverseCheckForDelete ? !fdata[deleteField] : fdata[deleteField];
      }
      return inverseCheck && entry === fdata['id']; //using id instead of uniqueKey as we are now assigning unique id to each and every row of data
    }).length
    return innerEntries !== 0;
  }).length;
  if (selectionModel.length > 0 && nonDeleteEntries !== selectionModel.length || type == EDataTableType.Prices && stateData?.totalCount > 1) {
    setDisableDelete(false)
  } else {
    setDisableDelete(true)
  }
}

function displayCheckboxSelection(moduleType, type) {
  return moduleType === EModuleType.Administration ||
    moduleType === EModuleType.FeatureFlags ||
    moduleType === EModuleType.Datamaintainance &&
    type === EDataTableType.Languages ||
    type === EDataTableType.Markets || type === EDataTableType.Properties;
}

const getImages = (params: GridRenderCellParams, col, openDialog) => {
  return <Stack direction="row" spacing={1}>
    {params.row[col.field].map((r) => <Chip size="small" key={r.type} label={r.type} onClick={() => openDialog(r, ECommonDialogType.Image)} color="primary" variant={r.isDefault ? 'filled' : 'outlined'} />)
    }
  </Stack>
}

const renderIconButton = (params: GridRenderCellParams, col, hasIcon: boolean, openDialog) => {
  return hasIcon ? <IconButton data-testid="btn-info-dialog" onClick={() => openDialog(params.row, col.type, EActionType.View, col)}>
    <InfoIcon color="primary" />
  </IconButton>
    : null
}

//This method render the cell based on the ECellType 
const createCell = (params: GridRenderCellParams, col: { type: any; field: string | number; hasIcon: any; key: string | number; }, t: (arg0: string) => string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined, openDialog: (data, type, action?, header?) => void, uniqueKey: string, type: EDataTableType) => {
  let val = '';

  switch (col.type) {
    case ECellType.Link:
      return <span className="link justify-left p-0" onClick={() => openDialog(params.row, type, EActionType.Edit)}>{params.row[col.field]}</span>
    case ECellType.Images:
      return getImages(params, col, openDialog)
    case ECellType.LongSalesText:
      return <Chip size="small" key={params.row[uniqueKey]} label={t('labels.view')} onClick={() => openDialog(params.row, ECommonDialogType.LongSalesText)} color="primary" variant="outlined" />
    case ECellType.Boolean:
      return params.row[col.field] ? t('labels.yes') : t('labels.no')
    case ECellType.Price:
      {
        const locale = countryCodes[params.row['countryCode']]['locale'];
        const price = params.row[col.field].toLocaleString('' + locale + '-u-nu-latn', { maximumFractionDigits: 2, minimumFractionDigits: 2 })
        return <span className="overflow-hidden overflow-ellipsis">{price}</span>
      }
    case ECellType.ObjectChips:
      val = params.row[col.field].map(data => data.code || data.name).join(', ');
      return <>
        <Tooltip title={val} placement="bottom" arrow>
          <span className="overflow-hidden overflow-ellipsis">{val}</span>
        </Tooltip>
        {renderIconButton(params, col, val && col.hasIcon, openDialog)}
      </>
    case ECellType.ArrayChips:
      val = params.row[col.field] ? params.row[col.field].join(', ') : null;
      return <>
        <Tooltip title={val} placement="bottom" arrow>
          <span className="overflow-hidden overflow-ellipsis">{val}</span>
        </Tooltip>
        {renderIconButton(params, col, col.hasIcon && val, openDialog)}
      </>
    case ECellType.Object:
      val = params.row[col.field];
      return <span className={'overflow-hidden overflow-ellipsis'}>{getDefaultValue(val)}</span>
    case ECellType.Date:
      val = formatDateString(params.row[col.field]);
      return <span className="overflow-hidden overflow-ellipsis">{val}</span>
    case ECellType.Update:
      {
        const value = params.row[col.field].includes('/') ? params.row[col.field].split('/') : null;
        return value ?
          <span className="overflow-hidden overflow-ellipsis updateCell">
            <span className="removed">{value[0]}</span>/<span className="added">{value[1]}</span></span> :
          <span className="overflow-hidden overflow-ellipsi">{params.row[col.field]}</span>
      }
    case ECellType.Details:
      return <>
        <IconButton data-testid="btn-info-dialog" onClick={() => openDialog(params.row, col.type, EActionType.View, col)}>
          <InfoIcon color="primary" />
        </IconButton>
      </>
    case ECellType.MaxDate:
      {
        const datesArr = params.row[col.key].map(data => data.modifiedOn);
        return <span className="overflow-hidden overflow-ellipsis">{getMaxDate(datesArr)}</span>
      }
    case ECellType.Tooltip:
      return <Tooltip title={params.row[col.field]} placement="bottom" arrow><span className="overflow-hidden overflow-ellipsis">{params.row[col.field]}</span></Tooltip>
    default:
      var bold = params?.row?.hasOption ? 'text-bold' : '';
      return <span className={`overflow-hidden overflow-ellipsis ${bold}`}>{params.row[col.field]}</span>
  }
}

const SearchBar = (props: any) => {
  const { stateData, searchValue, token, recordsPerPage, type, setSearchValue, setFilteredData, setPage, getDataList, t, productId, dispatch, } = props
  const searchFieldsMessage = searchFields[type].map(field => t('labels.' + field)).join(', ');
  return <Autocomplete
    id="product-search-box"
    clearOnBlur
    freeSolo
    value={searchValue}
    options={[]}
    className="search-bar"
    renderInput={(params) => <Tooltip title={searchFieldsMessage} arrow>
      <TextField {...params} className="search-inner-text" placeholder={searchFieldsMessage} InputProps={{
        ...params.InputProps,
        startAdornment: <InputAdornment position="end"> <SearchIcon />
        </InputAdornment>
      }}
      />
    </Tooltip>}
    onChange={(_, newVal) => {
      setSearchValue(newVal ? newVal : '');
      handleSearch({ newVal, recordsPerPage, token, stateData, getDataList, setPage, setFilteredData, productId, dispatch });
    }}
  />
}

const recordLengthPicker = (recordsPerPage: number, handleChange, t) => {
  return <div className="d-flex align-center recordPicker">
    {t('labels.recordsPerPage') + ':'}&emsp;
    <FormControl size="small" className="" style={{ width: '5.5rem' }}>
      <Select
        id="recordsPerPage"
        name="recordsPerPage"
        value={recordsPerPage}
        onChange={handleChange}
      >
        {RecordLength.map(range => <MenuItem key={range} value={range}>{range}</MenuItem>)}
      </Select>
    </FormControl>
  </div>
}

const compareButton = (handleCompare, t) => {
  return <>&emsp;<Button id="publish-button" variant="contained" onClick={handleCompare} startIcon={<CompareIcon id="compare-icon" />} className="common-btn text-capitalize">
    {t('button.compare')}
  </Button>
  </>
}

const ButtonSection = ({ disableDelete, handleCreate, handleDelete, handleCompare, t, token, type }: any) => {
  if (type === EDataMaintenanceType.ProductHierarchy) {
    return compareButton(handleCompare, t); // Assuming compareButton is a valid component
  } else {
    return (
      <>&emsp;
        <Button
          className="delete-button common-btn"
          color="error"
          variant="contained"
          disabled={(disableDelete)}
          startIcon={<DeleteIcon />}
          onClick={handleDelete}
        >
          {type == EDataMaintenanceType.Prices ? t('button.deleteAll') : t('button.delete')}
        </Button>
        &emsp;
        {type !== EDataMaintenanceType.Prices &&
          <>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => {
                handleCreate();
              }}
              className="text-capitalize common-btn"
            >
              {t('button.new')}
            </Button>
            {type === EDataTableType.Users && <>&emsp;<ExportDetails token={token} /></>}
          </>
        }
      </>
    );
  }
}

const renderButtonSection = (renderButtonSectionProps) => {
  const { moduleType, type, token, disableDelete, handleCreate, handleDelete, handleCompare, t } = renderButtonSectionProps;
  return type === EDataTableType.ProductHierarchy ||
    moduleType === EModuleType.Administration ||
    moduleType === EModuleType.FeatureFlags ||
    moduleType === EModuleType.Datamaintainance && (type === EDataTableType.Languages || type === EDataTableType.Prices) ||
    type === EDataTableType.Markets || type === EDataTableType.Properties ?
    <ButtonSection t={t} token={token} type={type} disableDelete={disableDelete} handleCreate={handleCreate} handleDelete={handleDelete} handleCompare={handleCompare} />
    : null;
}

const renderImportButton = (type, token) => {
  return displayImport[type] ? <FileUpload token={token} type={type} />
    : null;
}

const renderNoRecordsMessage = (searchValue, t) => {
  return <Stack height="100%" alignItems="center" justifyContent="center">
    <span style={{ color: searchValue ? 'red' : 'black' }}>
      {searchValue ? t('messages.noSearchRecords', { searchValue: searchValue }) : t('messages.noDataFound')}
    </span>
  </Stack>
}

const getTableClassName = (type: string) => {
  return `maintenanceTable ${type === EDataTableType.FeaturePropertyAssociation ? 'dialog-mode' : ''}`
}

export const MaintenanceTable = (props: IMaintenanceTableProps) => {
  const { token, type, getDataList, moduleType } = props;
  const { t } = useTranslation();
  const [stateData, setStateData] = useState([]);
  const [dataGridKey, setDataGridKey] = useState(''); //Key is used for proper functionality of DataGrid(like Filter feature)
  const [filteredData, setFilteredData] = useState([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [recordsPerPage, setRecordsPerPage] = useState<number>(RecordLength[0]); //Default records size to be fetched from RecordLength
  const [page, setPage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(1);
  const [disableDelete, setDisableDelete] = useState<boolean>(false);
  const tableHeaders = type == EDataTableType.PropertyAssociation || type == EDataTableType.FeaturePropertyAssociation ? props.columns : TableHeaders[type].main;
  const [selectionModel, setSelectionModel] = React.useState<RowId[]>([]);
  const deleteField = Array.isArray(deleteFields[type]) ? deleteFields[type].map(val => val.field) : deleteFields[type]?.field;
  const inverseCheckForDelete = Array.isArray(deleteFields[type]) ? deleteFields[type].map(val => val.inverseCheck) : deleteFields[type]?.inverseCheck;
  const uniqueKey = foreignKeysForTable[type];
  const uniqueShoringKey = initialSortField[type];
  const productId = props.productId;
  const tableData = useSelector((state: AppState) => state[moduleType][type]);
  const dispatch = useDispatch<AppDispatch>();

  //updated method for reduce cognitive complexities
  const openDialog = (data, dialogType: string, action?: string, header?) => {
    switch (dialogType) {
      case ECellType.ObjectChips:
      case ECellType.ArrayChips:
        dispatch(setDialogMessage({ show: true, message: data, type: dialogType, manipulationType: action, header: header, uniqueKey: uniqueKey }));
        break;
      case ECellType.Details:
        dispatch(setDialogMessage({ show: true, message: data, type: dialogType, manipulationType: action, header: header, detailsViewType: type, uniqueKey: uniqueKey }));
        break;
      case ECommonDialogType.Image:
        dispatch(setDialogMessage({ show: true, message: data, type: dialogType, manipulationType: dialogType, moduleType: moduleType }));
        break;
      case EDataMaintenanceType.ProductHierarchy:
        dispatch(setDialogMessage({ show: true, message: data, type: dialogType, manipulationType: action, moduleType: moduleType }));
        break;
      case ECommonDialogType.LongSalesText:
        dispatch(setDialogMessage({ show: true, message: data, type: dialogType, manipulationType: dialogType, moduleType: moduleType }));
        break;
      case ECommonDialogType.ShortSalesText:
        dispatch(setDialogMessage({ show: true, message: data, type: dialogType, manipulationType: dialogType, moduleType: moduleType }));
        break;
      case ECommonDialogType.Notification:
        dispatch(setDialogMessage({ show: true, message: data, type: dialogType, manipulationType: dialogType, moduleType: moduleType }));
        break;
      case ECommonDialogType.DeletePrices:
        dispatch(setDialogMessage({ show: true, message: data, type: dialogType, manipulationType: dialogType, moduleType: moduleType }));
        break;
      default:
        {
          const req = JSON.parse(JSON.stringify(selectionModel));
          const selectedIds = filteredData.filter(dataSelected => req.indexOf(dataSelected.id) >= 0).map(validData => validData[uniqueKey]);
          const newdata = { ...data, searchValue: searchValue, recordsPerPage: recordsPerPage, page: page, selectedIds: selectedIds };
          dispatch(setDialogMessage({ show: true, message: newdata, type: dialogType, manipulationType: action, moduleType: moduleType, callDeleteAPI: callDeleteAPI }));
        }
    }
  }

  const columns: GridColDef[] = createGridColumns(tableHeaders, t, openDialog, uniqueKey, type);

  useEffect(() => {
    loadFormData(props, type, tableData, page, recordsPerPage, searchValue, dispatch);
  }, [])

  useEffect(() => {
    handleModuleTypeChange({ tableData, page, setStateData, setFilteredData, setSearchValue, setRecordsPerPage, setPageCount });
  }, [tableData])

  useEffect(() => {
    disableDeleteBtn({ filteredData, selectionModel, deleteField, inverseCheckForDelete, setDisableDelete, stateData, type });
    setDataGridKey(searchValue + recordsPerPage + page);
  })

  const handleChange = (event: React.ChangeEvent<unknown>, value?: number) => {
    if (event.target.name === 'recordsPerPage') {
      const records = event.target.value;
      setRecordsPerPage(records);
      handleRecordsChange({ page, records, type, searchValue, setPage, productId, props, dispatch });
    } else {
      setPage(value);
      handlePageChange({ value, recordsPerPage, type, searchValue, stateData, setFilteredData, productId, props, dispatch });
    }
  };

  const handleCreate = () => {
    openDialog({}, type, EActionType.Create)
  }

  const handleDelete = () => {
    openDialog({}, type, EActionType.Delete);
  };

  const handleCompare = () => {
    openDialog({}, type, EActionType.Compare);
  };

  const callDeleteAPI = () => {
    const req = JSON.parse(JSON.stringify(selectionModel));
    const selectedIds = filteredData.filter(f => req.indexOf(f.id) >= 0 && f.isActive).map(f1 => f1[uniqueKey]);
    if (type === EDataTableType.ApiKeys) {
      const selectedKeys = selectedIds.map(id => filteredData.find(fid => fid.id === id).key);
      props.deleteItems(selectedIds, selectedKeys);
    } else {
      props.deleteItems(selectedIds);
    }
    dispatch(setDialogMessage({ show: false }));
    setSelectionModel([]);
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Grid container spacing={2}>
          <Grid item xs={6} className="d-flex align-center">
            {SearchBar({ stateData, searchValue, token, recordsPerPage, type, setSearchValue, setFilteredData, setPage, getDataList, dispatch, t, productId })}
            &emsp;
            {recordLengthPicker(recordsPerPage, handleChange, t)}
            &emsp;
            <GridToolbarColumnsButton />
            &emsp;
            <GridToolbarFilterButton />
            &emsp;
            <Button className="refresh-button common-btn" color="primary" variant="text" startIcon={<RefreshIcon />} onClick={() => {
              handleRefresh({ props, page, recordsPerPage, productId, dispatch })
            }}
            >
              {t('button.refresh')}
            </Button>
          </Grid>
          <Grid item xs={6} className="d-flex justify-end">
            {renderImportButton(type, token, displayImport)}
            {renderButtonSection({ moduleType, type, token, disableDelete, handleCreate, handleDelete, handleCompare, t })}
          </Grid>
        </Grid>
      </GridToolbarContainer>
    );
  }

  return <Box data-testid="data-table" className={getTableClassName(type)}>
    <DataGrid
      key={dataGridKey}
      rows={filteredData}
      columns={columns}
      getRowId={(row) => { //To set key to row
        return row['id']
      }}
      disableRowSelectionOnClick
      disableColumnMenu
      hideFooter
      rowHeight={35}
      columnHeaderHeight={35}
      style={{ height: '75%' }}
      initialState={{ //To set default sort on first render
        sorting: {
          sortModel: !uniqueShoringKey ? [] : [{ field: uniqueShoringKey, sort: 'asc' }],
        },
      }}
      slots={{
        toolbar: CustomToolbar,
        noRowsOverlay: () => renderNoRecordsMessage(searchValue, t)
      }}
      slotProps={{
        columnsManagement: {
          disableShowHideToggle: true, //To hide the HIDE ALL button in Manage Columns option in Column Menu
        },
      }}
      checkboxSelection={displayCheckboxSelection(moduleType, type)}
      onRowSelectionModelChange={(newSelection) => {
        setSelectionModel(newSelection);
      }}
      rowSelectionModel={selectionModel}
    /><br />

    {filteredData?.length > 0 && <Stack spacing={2} style={{ alignItems: 'center' }}>
      <Pagination color="primary" count={pageCount} page={page} onChange={handleChange} />
    </Stack>}
  </Box>
}
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RoleApi } from '../../api/RoleApi';

//To get paginated Roles data
export const getRoles = createAsyncThunk( 'role/getRoles', async ( action:{ token: string, page:number, limit:number, searchCode?: string } )=>{
  return RoleApi.getRoles( action );
} )

//To create a role
export const createRole = createAsyncThunk( 'role/createRole', async ( action:{ token: string, request } )=>{
  return RoleApi.createRole( action );
} );

//To update a role
export const updateRole = createAsyncThunk( 'role/updateRole', async ( action:{ token: string, request } )=>{
  return RoleApi.updateRole( action );
} );

//To delete a role
export const deleteRoles = createAsyncThunk( 'role/deleteRoles', async ( action:{ token: string, request } )=>{
  return RoleApi.deleteRoles( action );
} );

//To get a role details
export const getRoleDetails = createAsyncThunk( 'role/getRoleDetails', async ( action:{ token: string, request } )=>{
  return RoleApi.getRoleDetails( action );
} );

//To get all roles for user tab - create and update user
export const getAllRoles = createAsyncThunk( 'role/getAllRoles', async ( action:{ token: string, page:number, limit:number, searchCode?: string} )=>{
  return RoleApi.getRoles( action );
} )

const initialState = {
  data:[]
}

const roleSlice = createSlice( {
  name: 'roles',
  initialState,
  reducers: {},
  extraReducers: ( builder ) =>{ //To get all Roles and updates the data to state
    builder.addCase( getAllRoles.fulfilled, ( state, action ) => {
      if( !action.payload.error ) {
        state.data = action.payload.data;
      }
    } )
  }
} )

export default roleSlice.reducer;
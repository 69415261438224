import { AppStore } from '../store/AppStore';
import { EAction, EDataTableType, EPropertyOrigin } from '../data/Constants';
import { resetError, setError } from '../store/states/ErrorSlice';
import { t } from 'i18next';
import { resetAlertMessage, setAlertMessage } from '../store/states/AlertMessageSlice';
import { resetDialogMessage } from '../store/states/DialogSlice';
import { IEnumOption, IEnumValue, IPropertyData } from '../../types';


//update feature property Association data to the state
export function updateFeaturePropertyAssociation( state, payload, type ) {
  if( !payload.searchKey ) {
    return updateMaintenanceData( state, payload, 'featurePropertyAssociation' );
  } else {
    return updateMaintenanceSearchData( state, payload, 'featurePropertyAssociation' );
  }
}

export function updateMaintenanceData( state, action, type ) {
  const newData = JSON.parse( JSON.stringify( {...state[type]} ) );
  if( action.page === 1 ) {
    newData['data'] = {} // To reset data on every load of first page as change in recordLength might affect the functionality
  }
  if( Object.keys( state[type].data ).length > 5 ) { // Delete the second entry of data element as first element is the initial load data
    delete newData['data'][Object.keys( newData.data )[1]]
  }
  newData['searchKey'] = '';
  if ( type !== EDataTableType.ApiKeys ) {
    action.data = action.data?.map( ( item, index ) => ( { ...item, id: action.page + '-' + index + 1 } ) )
  }
  newData['data'] = {...newData['data'],[action.page]:action.data ? action.data : []};
  newData['currentPage'] = action.page;
  newData['totalCount'] = action.totalRecords;
  newData['recordsPerPage'] = action.recordsPerPage ? action.recordsPerPage : 15;
  return { ...state, [type]:newData };    
}
  
export function updateMaintenanceSearchData ( state, action, type ) {
  const newData = {...state[type]};
  if( action.page === 1 ) {
    newData['searchData'] = {} // To reset searchData on every load of first page as change in recordLength might affect the functionality
  }
  if( Object.keys( state[type].searchData ).length > 5 ) { // Delete the second entry of searchData element as first element is the initial load data
    delete newData['searchData'][Object.keys( newData.searchData )[1]]
  }
  newData['searchKey'] = action.searchKey;
  if ( type !== EDataTableType.ApiKeys ) {
    action.data = action.data.map( ( item, index ) => ( { ...item, id: action.page + '-' + index + 1 } ) )
  }
  newData['searchData'] = {...newData['searchData'],[action.page]:action.data ? action.data : []};
  newData['currentPage'] = action.page;
  newData['totalCount'] = action.totalRecords;
  newData['recordsPerPage'] = action.recordsPerPage ? action.recordsPerPage : 15;
  return { ...state, [type]:newData };   
}

//To derive deletion message
export const joinSelectedKeys = ( keySelected ) => {
  return keySelected.join( ', ' ) + '.';
}

export function handleError( err:any,action?:string,apiType?:string ) {
  const errObj = {
    type: apiType ? apiType : '',
    code: err.response?.status,
    action: action ? action : '',
    message: err.response?.data?.Message || ( err.response?.data.errors ? Object.values( err.response.data.errors )[0][0] : '' ),
  }
  AppStore.dispatch( setError( errObj ) )
  if( action == EAction.Get ) {
    const message = errObj.message ? errObj.message : t( 'messages.fail.getDetails' )
    AppStore.dispatch( setAlertMessage( {show:true,message:message,type:'error'} ) );
    AppStore.dispatch( resetDialogMessage() )
  }
  return {
    error: errObj
  };
}

export const formatDateString = ( dateValue: string )=> {
  const date = new Date( dateValue );
  const month = ( '0' + ( date.getMonth() + 1 ) ).slice( -2 );
  const day = ( '0' + date.getDate() ).slice( -2 );
  const year = date.getFullYear();
  const hour = ( '0' + date.getHours() ).slice( -2 );
  const min = ( '0' + date.getMinutes() ).slice( -2 );
  const seg = ( '0' + date.getSeconds() ).slice( -2 );
  return month + '-' + day + '-' + year + ' ' + hour + ':' + min + ':' + seg + ' UTC';
} 

/**
 * To get the maximum date from given dates in string Array
 * @param {string[]} datesArray Array of dates
 * @returns {string} Maximum date in Formatted way
 */
export const getMaxDate = ( datesArray: string[] ) => {
  return datesArray.length > 0 ? formatDateString( datesArray.reduce( ( a, b ) => a > b ? a : b ) ) : ''; 
}

//To loop through all features and options and Create Rows data to display on Features table
export const getFeaturePropertyRows = ( features, searchKey ) =>{
  const rows:any[] = [];
  let featureRow = {};
  features?.sort();
  features?.forEach( ( feature ) => {
    if( !searchKey && feature.featureCode ) {
      featureRow = {
        code:feature.featureCode,
        description:feature.featureName,
        hasOption : feature.options && feature.options.length > 0 ? true : false
      }
      feature.associations?.map( ( property )=>{   
        featureRow[property.code] = property.name
      } )

      rows.push( featureRow );

      if( feature.options ) {
        feature.hasOption = false;
        let optionRow = {};
        feature.options.forEach( ( option ) =>{
          optionRow = {
            code:option.optionCode,
            description:option.optionName
          }
          option?.properties?.map( ( property )=>{   
            optionRow[property.code] = property.name
          } )

          rows.push( optionRow );
        } )
      } 
    } else if( searchKey ) {
      getSearchFeatureAssociationRows( feature, searchKey, rows );
    }
  } );
    
  return rows;
}

//Get the Product Property association rows from the api response
export const getProductPropertyAssociationRows = ( products ) =>{
  const rows:any[] = [];
  products?.forEach( ( product ) => {
    product['description'] = product.productName
    product.associations?.map( ( property )=>{   
      product[property.code] = property.name
    } )
    rows.push( product ); 
  } );
  return rows;
}

//Get Search Feature/option rows from response
export const getSearchFeatureAssociationRows = ( feature, searchKey, rows ) =>{
  const isFeatureSearch = feature.featureCode.toLowerCase().includes( searchKey.toLowerCase() ) || feature.featureName.toLowerCase().includes( searchKey.toLowerCase() ) ? true : false;
  if( !isFeatureSearch ) {
    const option = feature?.options?.find( opt=>opt.optionCode.toLowerCase().includes( searchKey.toLowerCase() ) || opt.optionName.toLowerCase().includes( searchKey.toLowerCase() ) )
    if( option ) {
      const searchOptionRow = {
        code:option.optionCode,
        description:option.optionName
      }
      option?.associations?.map( ( property )=>{   
        searchOptionRow[property.code] = property.name
      } )
      rows.push( searchOptionRow );
    }
  } else{
    const searchFeatureRow = {
      code:feature.featureCode,
      description:feature.featureName
    }
    feature?.associations?.map( ( property )=>{   
      searchFeatureRow[property.code] = property.name
    } )
    rows.push( searchFeatureRow );
  }
  return rows;
}

//returns the response from api in paginated format
export function handlePaginatedData( response, action, total ) {
  return {
    data: response.data,
    searchKey: action.searchCode,
    page: action.page,
    totalRecords: total,
    recordsPerPage:action.limit
  }
}

//Get enum values option from the lov list
export const getEnumOptions = ( enumValues:IEnumValue[], value: string|boolean ):IEnumOption[] =>{
  const values = enumValues.find( ( v:IEnumValue )=> v.code === value );
  return values ? values.options : [];
}

//Set the Properties columns data
export function setColumnsData( properties:IPropertyData[], setColumns, tableColumns ) {
  const cols = [...tableColumns]
  properties.forEach( ( property ) => {
    if ( property.origin === EPropertyOrigin.Pdm && property.isActive ) {
      cols.push( { field: property?.code, width: 100, hasNoTranslation: true,applicability:property.applicability } )
    }
  } );
  setColumns( cols );
}

//To Reset Error and Alert messages
export function resetAllErrorInfo() {
  AppStore.dispatch( resetDialogMessage() );
  AppStore.dispatch( resetAlertMessage() );
  AppStore.dispatch( resetError() );
}

